<template>
  <div class="public-body">
    <div class="public-body__block" style="text-align: center;">
      <img src="@/assets/img/club-ruy-logo.svg" alt="logo" />
    </div>
    <div class="public-body__block">
      <div class="public-body__title  title">ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</div>
      <div class="public-body__subt">Автоматизированной информационной системы «Клуб Российского Союза Молодежи» Общероссийской общественной организации «Российский Союз Молодежи»
      </div>
      <div class="public-body__subt">1. Общие положения</div>
      <ul class="public-body__list">
        <li>1.1. Общероссийская общественная организация «Российский Союз Молодежи» (далее – РСМ) предлагает пользователю сети Интернет (далее – Пользователь) использовать Автоматизированную информационную систему «Клуб Российского Союза Молодежи» (далее – АИС), включающую в себя интернет-сайт https://club.ruy.ru и интернет-сайты, созданные с использованием функционала https://ruy.ru, на условиях, изложенных в настоящем Пользовательском соглашении (далее – Соглашение). Соглашение вступает в силу с момента выражения Пользователем согласия с его условиями в порядке, предусмотренном п. 1.4 Соглашения.</li>
        <li>1.2. Соглашение разработано РСМ и определяет условия использования и развития АИС, а также права и обязанности Пользователей АИС. Соглашение распространяется также на отношения, связанные с правами и интересами третьих лиц, не являющихся Пользователями АИС, но чьи права и интересы могут быть затронуты в результате действий Пользователей в АИС.</li>
        <li>1.3. Соглашение является юридически обязательным соглашением между Пользователем и РСМ, предметом которого является предоставление Пользователю доступа к использованию АИС и её функционала. Помимо настоящего Соглашения, к соглашению между Пользователем и РСМ относятся все документы, регулирующие предоставление доступа к использованию функционала АИС и размещенные в соответствующих разделах на интернет-сайте АИС.</li>
        <li>1.4. Пользователь обязан полностью ознакомиться с правилами настоящего Соглашения до момента регистрации на АИС. Регистрация Пользователя на АИС означает полное и безоговорочное принятие Пользователем условий Соглашения в соответствии со статьей 438 Гражданского кодекса Российской Федерации.</li>
        <li>1.5. Использование сервисов АИС регулируется настоящим Соглашением, а также Положением о политике РСМ в отношении обработки персональных данных (размещено в разделе «Документы» <a href="https://ruy.ru/" target="_blank">https://ruy.ru/</a>).</li>
        <li>1.6. Соглашение может быть изменено РСМ в одностороннем порядке без какого-либо специального уведомления, новая редакция Соглашения вступает в силу с момента ее размещения в сети Интернет. Действующая редакция Соглашения находится на сайте РСМ по адресу <a href="https://ruy.ru/" target="_blank">https://ruy.ru/</a> в разделе «Документы». РСМ рекомендует Пользователям регулярно проверять условия Соглашения на предмет их изменения и/или дополнения. Продолжение использования АИС Пользователем после внесения изменений и/или дополнений в Соглашение означает принятие и согласие Пользователя с такими изменениями и/или дополнениями.</li>
        <li>1.7. Пройдя процедуру регистрации в АИС и начав использовать какой-либо сервис АИС и (или) его отдельные функции, Пользователь считается принявшим условия Соглашения в полном объеме, без всяких оговорок и исключений. В случае несогласия Пользователя с какими-либо из положений Соглашения, Пользователь не вправе использовать сервисы АИС и (или) его отдельные функции. В случае если РСМ были внесены какие-либо изменения в Соглашение в порядке, предусмотренном пунктом 1.6 настоящего Соглашения, с которыми Пользователь не согласен, он обязан прекратить использование сервисов АИС и (или) его отдельных функций.</li>
      </ul>
      <div class="public-body__subt">2. Регистрация Пользователя. Учетная запись Пользователя</div>
      <ul class="public-body__list">
        <li>2.1. Для того чтобы воспользоваться сервисами АИС и (или) его отдельными функциями, Пользователю необходимо пройти процедуру регистрации, в результате которой для Пользователя будет создана уникальная учетная запись. При регистрации Пользователь принимает условия настоящего Соглашения и даёт своё согласие на обработку персональных данных на условиях, определенных Политикой обработки персональных данных РСМ.</li>
        <li>2.2. Для регистрации Пользователь обязуется предоставить достоверную и полную информацию, предлагаемую к заполнению в форме регистрации, и поддерживать эту информацию в актуальном состоянии. Если Пользователь предоставляет неверную информацию или у РСМ есть основания полагать, что предоставленная Пользователем информация неполная или недостоверна, РСМ имеет право по своему усмотрению заблокировать либо удалить учетную запись Пользователя и отказать Пользователю в использовании своих сервисов (либо их отдельных функций).</li>
        <li>После регистрации Пользователь может загрузить изображение для учетной записи (аватар). Изображение учетной записи может использоваться для сопровождения опубликованных Пользователем материалов в рамках использования сервисов АИС. В случае использования в качестве изображения учетной записи изображения Пользователя, Пользователь осознает и соглашается с тем, что РСМ вправе проводить опубликование и дальнейшее использование изображения Пользователя для использования в сервисах АИС и на других интернет-сайтах, использующих функционал АИС, в рекламных продуктах, корпоративных блогах и аккаунтах РСМ на сторонних ресурсах.</li>
        <li>Изображение учетной записи и имя Пользователя в комментариях и отзывах должно соответствовать требованиям раздела 4 настоящего Соглашения.</li>
        <li>2.3. РСМ оставляет за собой право в любой момент потребовать от Пользователя подтверждения данных, указанных при регистрации учетной записи Пользователя на АИС, а также запросить иную информацию о Пользователе, связанную с использованием сервисов АИС и/или Интернет-ресурсов партнеров РСМ. Для целей проверки заявленных Пользователем данных РСМ вправе запросить подтверждающие документы (в частности – документы, удостоверяющие личность), непредставление которых по усмотрению РСМ может быть приравнено к предоставлению недостоверной информации и повлечь последствия, предусмотренные п. 2.2 настоящего Соглашения. В случае если данные Пользователя, указанные в предоставленных им документах, не соответствуют данным, указанным при регистрации, а также в случае, когда данные, указанные при регистрации, не позволяют идентифицировать Пользователя, РСМ вправе отказать Пользователю в доступе к учетной записи и использовании сервисов АИС и (или) его отдельных функций.</li>
        <li>2.4. Персональные данные Пользователя, содержащиеся в учетной записи Пользователя, обрабатываются РСМ в соответствии с условиями Положения о политике РСМ в отношении обработки персональных данных.</li>
        <li>2.5. Средства для доступа к учетной записи Пользователя.</li>
        <li>2.5.1. При регистрации Пользователь указывает имя, фамилию, дату рождения, пол, адрес электронной почты, телефон и пароль для доступа к учетной записи. РСМ вправе запретить использование определенных фамилий, имен, а также устанавливать требования к логину и паролю (длина, допустимые символы и т.д.).</li>
        <li>2.5.2. После осуществления Пользователем входа в учетную запись, данные учетной записи могут быть автоматически сохранены в браузере устройства до завершения Пользователем работы под своей учетной записью и не требовать дополнительного ввода средств доступа к учетной записи при каждом использовании сервисов АИС. Автоматически сохраненные в браузере устройства данные учетной записи Пользователя могут быть использованы для доступа к сервисам АИС, включая интернет-сайты, созданные с использованием функционала АИС, приложения и иные интернет-сайты партнеров РСМ, за исключением случаев, указанных в п. 2.8.2 настоящего Соглашения.</li>
        <li>2.6. Пользователь самостоятельно несет ответственность за безопасность (в том числе устойчивость к угадыванию) выбранных им средств для доступа к учетной записи, а также самостоятельно обеспечивает их конфиденциальность. Пользователь самостоятельно несет ответственность за все действия (а также их последствия) в рамках или с использованием сервисов АИС под учетной записью Пользователя, включая случаи добровольной передачи Пользователем данных для доступа к учетной записи Пользователя третьим лицам на любых условиях (в том числе по договорам или соглашениям). При этом все действия в рамках или с использованием сервисов АИС под учетной записью Пользователя считаются произведенными самим Пользователем, за исключением случаев, когда Пользователь, в порядке, предусмотренном п. 2.7 Соглашения, уведомил техническую поддержку АИС о несанкционированном доступе к сервисам АИС с использованием учетной записи Пользователя и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своих средств доступа к учетной записи.</li>
        <li>2.7. Пользователь обязан немедленно уведомить техническую поддержку АИС, направив электронное письмо на адрес <a href="mailto:webmaster@ruy.ru" target="_blank">webmaster@ruy.ru</a> о любом случае несанкционированного (не разрешенного Пользователем) доступа к сервисам АИС с использованием учетной записи Пользователя и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своих средств доступа к учетной записи. В целях безопасности Пользователь обязан самостоятельно осуществлять безопасное завершение работы под своей учетной записью (кнопка «Выйти из профиля») по окончании каждой сессии работы с сервисами АИС. РСМ не отвечает за возможную потерю или порчу данных, а также другие последствия любого характера, которые могут произойти из-за нарушения Пользователем положений этой части Соглашения.</li>
        <li>2.8. Использование Пользователем своей учетной записи.</li>
        <li>2.8.1. Пользователь не вправе воспроизводить, повторять и копировать, продавать и перепродавать, а также использовать для каких-либо коммерческих целей какие-либо части сервисов АИС (включая контент, доступный Пользователю посредством сервисов), или доступ к ним, кроме тех случаев, когда Пользователь получил такое разрешение от РСМ, либо когда это прямо предусмотрено пользовательским соглашением какого-либо сервиса.</li>
        <li>2.8.2. Некоторые категории учетных записей Пользователей могут ограничивать или запрещать использование некоторых сервисов АИС или их отдельных функций в случае, если это предусмотрено при регистрации, либо в условиях использования того или иного сервиса.</li>
        <li>2.9. Прекращение регистрации.</li>
        <li>2.9.1. РСМ вправе заблокировать или удалить учетную запись Пользователя, а также запретить доступ с использованием какой-либо учетной записи к сервисам АИС, и удалить любой контент без объяснения причин, в том числе в случае нарушения Пользователем условий настоящего Соглашения или условий иных документов, предусмотренных п. 1.6 Соглашения, а также в случае неиспользования соответствующего сервиса.</li>
        <li>2.10. Удаление учетной записи Пользователя.</li>
        <li>2.10.1. Пользователь вправе в любой момент удалить свою учетную запись на АИС или при наличии соответствующей функции прекратить ее действие в отношении некоторых из них.</li>
        <li>2.10.2. Удаление учетной записи АИС осуществляется в следующем порядке:</li>
        <li>2.10.2.1. Пользователь направляет на адрес технической поддержки АИС (<a href="mailto:webmaster@ruy.ru" target="_blank">webmaster@ruy.ru</a>) письмо с запросом на удаление учетной записи с приложением подписанного запроса на прекращение обработки персональных данных;</li>
        <li>2.10.2.2. Техническая поддержка АИС по получению запроса Пользователя блокирует учетную запись Пользователя на срок один месяц, в течение которого доступ Пользователя к своей учетной записи становится невозможен, при этом контент, размещенный с помощью такой учетной записи может быть удален;</li>
        <li>2.10.2.3. Если в течение указанного срока в п. 2.10.2.2 учетная запись Пользователя будет восстановлена Пользователем, доступ к учетной записи для Пользователя восстановится, однако контент, размещенной с ее помощью, может не подлежать восстановлению;</li>
        <li>2.10.2.4. Если в течение указанного выше срока учетная запись Пользователя не будет восстановлена, то учетная запись Пользователя и весь контент, размещенный с ее использованием, будет удален. С этого момента восстановление учетной записи, какой-либо информации, относящейся к ней, а равно доступов к сервисам АИС с использованием этой учетной записи - невозможны.</li>
      </ul>
      <div class="public-body__subt">3. Общие положения об использовании и хранении информации</div>
      <ul class="public-body__list">
        <li>3.1. РСМ вправе устанавливать ограничения в использовании сервисов АИС для всех Пользователей, либо для отдельных категорий Пользователей (в зависимости от места пребывания Пользователя, языка, на котором предоставляется сервис и т.д.), в том числе, наличие или отсутствие отдельных функций сервиса АИС.</li>
        <li>3.2. РСМ вправе посылать своим Пользователям информационные сообщения. Используя сервисы АИС, Пользователь также в соответствии с ч. 1 ст. 18 Федерального закона от 13.03.2006 № 38-ФЗ «О рекламе» дает свое согласие на получение сообщений рекламного характера. Пользователь вправе отказаться от получения сообщений рекламного характера путем использования соответствующего функционала того сервиса АИС, в рамках которого или в связи с которым Пользователем были получены сообщения рекламного характера.</li>
        <li>РСМ вправе оповещать других Пользователей Сервиса о публичных действиях Пользователя, в том числе о размещении им новых публикаций, о произведенных им действиях в отношении контента других Пользователей, а также иной активности, совершенной им в рамках сервисов АИС.</li>
        <li>3.3. В целях повышения качества сервисов АИС, привлекаемые РСМ для проведения опроса лица вправе осуществлять сбор мнений и отзывов Пользователей по различным вопросам путем направления информационного сообщения при очередном посещении Пользователем сервиса либо осуществления связи по контактным данным, указанным Пользователем в учетной записи (посредством телефонных звонков или электронных писем). Собранные мнения и отзывы могут быть использованы для формирования статистических данных, которые могут быть использованы в сервисах АИС. Отзывы, предоставленные Пользователем при проведении опроса, также могут быть опубликованы РСМ в сервисах АИС либо в сервисах, интернет-сайтов партнеров РСМ, как с указанием имени (логина) Пользователя, так и без указания. При оставлении отзывов Пользователь обязуется руководствоваться требованиями настоящего Соглашения, в том числе требованиями, установленными п. 5 настоящего Соглашения.</li>
      </ul>
      <div class="public-body__subt">4. Контент Пользователя</div>
      <ul class="public-body__list">
        <li>4.1. Пользователь самостоятельно несет ответственность за соответствие содержания размещаемого Пользователем контента требованиям действующего законодательства, включая ответственность перед третьими лицами в случаях, когда размещение Пользователем того или иного контента или содержание контента нарушает права и законные интересы третьих лиц, в том числе личные неимущественные права авторов, иные интеллектуальные права третьих лиц, и/или посягает на принадлежащие им нематериальные блага.</li>
        <li>4.2. Пользователь признает и соглашается с тем, что РСМ не обязана проверять и просматривать контент любого вида, размещаемый и/или распространяемый Пользователем на АИС, а также то, что РСМ имеет право (но не обязанность) по своему усмотрению отказать Пользователю в размещении контента на АИС. Пользователь осознает и согласен с тем, что он должен самостоятельно оценивать все риски, связанные с использованием контента, включая оценку надежности, полноты или полезности этого контента.</li>
        <li>4.3. Пользователь осознает и соглашается с тем, что технология работы сервисов АИС может потребовать копирование (воспроизведение) контента Пользователя АИС.</li>
      </ul>
      <div class="public-body__subt">5. Условия использования сервисов АИС</div>
      <ul class="public-body__list">
        <li>5.1. Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с использованием АИС, в том числе, если такие действия приведут к нарушению прав и законных интересов третьих лиц, а также за соблюдение действующего законодательства Российской Федерации при использовании АИС.</li>
        <li>5.2. При использовании сервисов АИС Пользователь не вправе:</li>
        <li>5.2.1. Загружать, посылать, передавать или любым другим способом размещать и/или распространять контент, который является незаконным, вредоносным, клеветническим, оскорбляет нравственность, демонстрирует (или является пропагандой) насилия и жестокости, нарушает права интеллектуальной собственности, пропагандирует ненависть и/или дискриминацию людей по расовому, этническому, половому, религиозному, социальному признакам, содержит оскорбления в адрес каких-либо лиц или организаций, содержит элементы (или является пропагандой) порнографии, эротики, представляет собой рекламу (или является пропагандой) услуг сексуального характера (в том числе под видом иных услуг), разъясняет порядок изготовления, применения или иного использования наркотических веществ или их аналогов, взрывчатых веществ, оружия или боеприпасов;</li>
        <li>5.2.2. Нарушать права третьих лиц, в том числе несовершеннолетних лиц и/или причинять им вред в любой форме;</li>
        <li>5.2.3. Выдавать себя за другого человека или представителя организации и/или сообщества без достаточных на то прав, в том числе за работников РСМ, модераторов, экспертов или владельца АИС, а также применять любые другие формы и способы незаконного представительства других лиц в сети Интернет, а также умышленно вводить Пользователей или РСМ в заблуждение относительно свойств и характеристик каких-либо субъектов или объектов;</li>
        <li>5.2.4. Загружать, посылать, передавать или любым другим способом размещать и/или распространять контент, при отсутствии прав на такие действия согласно действующему законодательству Российской Федерации или каким-либо договорным отношениям;</li>
        <li>5.2.5. Загружать, посылать, передавать или любым другим способом размещать и/или распространять не разрешенную специальным образом рекламную информацию, спам (в том числе и поисковый), списки чужих адресов электронной почты, схемы «пирамид», многоуровневого (сетевого) маркетинга (MLM), системы интернет-заработка и e-mail-бизнесов, «письма счастья», а также использовать сервисы АИС для участия в этих мероприятиях, или использовать сервисы АИС, исключительно для перенаправления Пользователей на страницы других доменов;</li>
        <li>5.2.6. Загружать, посылать, передавать или любым другим способом размещать и/или распространять какие-либо материалы, содержащие вирусы или другие компьютерные коды, файлы или программы, предназначенные для нарушения, уничтожения либо ограничения функциональности любого компьютерного или телекоммуникационного оборудования или программ, для осуществления несанкционированного доступа, а также серийные номера к коммерческим программным продуктам и программы для их генерации, логины, пароли и прочие средства для получения несанкционированного доступа к платным ресурсам в сети Интернет, а также размещения ссылок на вышеуказанную информацию;</li>
        <li>5.2.7. Осуществлять обработку персональных данных других лиц, зарегистрированных на АИС, без их на то согласия;</li>
        <li>5.2.8. Нарушать нормальную работу веб-сайтов и сервисов АИС;</li>
        <li>5.2.9. Содействовать действиям, направленным на нарушение ограничений и запретов, налагаемых Соглашением;</li>
        <li>5.2.10. Другим образом нарушать нормы действующего законодательства Российской Федерации, в том числе нормы международного права.</li>
      </ul>
      <div class="public-body__subt">6. Исключительные права на содержание сервисов и контент</div>
      <ul class="public-body__list">
        <li>6.1. Все объекты, доступные при помощи сервисов АИС, в том числе элементы дизайна, текст, графические изображения, иллюстрации, видео, программы для ЭВМ, базы данных, музыка, звуки и другие объекты (далее – содержание сервисов), а также любой контент, размещенный на сервисах АИС, являются объектами исключительных прав РСМ, Пользователей и других правообладателей.</li>
        <li>6.2. Использование контента, а также каких-либо иных элементов сервисов возможно только в рамках функционала, предлагаемого тем или иным сервисом АИС. Никакие элементы содержания сервисов РСМ, а также любой контент, размещенный на сервисах РСМ, не могут быть использованы иным образом без предварительного разрешения правообладателя. Под использованием подразумеваются, в том числе: воспроизведение, копирование, переработка, распространение на любой основе, отображение во фрейме и т.д. Исключение составляют случаи, прямо предусмотренные законодательством РФ или условиями использования того или иного сервиса АИС.</li>
        <li>Использование Пользователем элементов содержания сервисов, а также любого контента, размещенного на АИС для личного некоммерческого использования, допускается при условии сохранения всех знаков охраны авторского права, смежных прав, товарных знаков, других уведомлений об авторстве, сохранения имени (или псевдонима) автора/наименования правообладателя в неизменном виде, сохранении соответствующего объекта в неизменном виде. Исключение составляют случаи, прямо предусмотренные законодательством Российской Федерации или пользовательскими соглашениями того или иного сервиса АИС.</li>
      </ul>
      <div class="public-body__subt">7. Сайты и контент третьих лиц</div>
      <ul class="public-body__list">
        <li>7.1. Сервисы АИС могут содержать ссылки на другие сайты в сети Интернет (сайты партнеров РСМ и третьих лиц). Указанные третьи лица и их контент не проверяются РСМ на соответствие тем или иным требованиям (достоверности, полноты, законности и т.п.). РСМ не несет ответственность за любую информацию, материалы, размещенные на сайтах третьих лиц, к которым Пользователь получает доступ с использованием сервисов, в том числе, за любые мнения или утверждения, выраженные на сайтах третьих лиц, рекламу и т.п., а также за доступность таких сайтов или контента и последствия их использования Пользователем.</li>
        <li>7.2. Ссылка (в любой форме) на любой сайт, продукт, услугу, любую информацию коммерческого или некоммерческого характера, размещенная на АИС, не является одобрением или рекомендацией данных продуктов (услуг, деятельности) со стороны АИС, за исключением случаев, когда на это прямо указывается на ресурсах АИС.</li>
      </ul>
      <div class="public-body__subt">8. Отсутствие гарантий, ограничение ответственности</div>
      <ul class="public-body__list">
        <li>8.1. Сервисы АИС предоставляются в состоянии «как есть». РСМ не принимает на себя никакой ответственности, в том числе за соответствие сервисов целям Пользователя.</li>
        <li>8.2. РСМ не гарантирует то, что: сервисы АИС соответствуют либо будут соответствовать требованиям Пользователя; сервисы будут предоставляться непрерывно, быстро, надежно и без ошибок; результаты, которые могут быть получены с использованием сервисов, будут точными и надежными и могут использоваться для каких-либо целей или в каком-либо качестве (например, для установления и/или подтверждения каких-либо фактов); качество какого-либо продукта, услуги, информации и пр., полученных с использованием сервисов, будет соответствовать ожиданиям Пользователя.</li>
        <li>8.3. Пользователь самостоятельно несет ответственность за возможные последствия использования информации и/или материалов, доступ к которым Пользователь получает в сервисах АИС, в том числе несет ответственность за ущерб, который это использование может причинить компьютеру Пользователя или третьим лицам, за потерю данных или любой другой вред.</li>
        <li>8.4. РСМ не несет ответственности за любые виды убытков, произошедшие вследствие использования Пользователем сервисов АИС или отдельных частей/функций её сервисов.</li>
        <li>8.5. РСМ не несёт ответственности за обработку персональных данных, доступ к которым Пользователи самостоятельно предоставляют другим Пользователям с использованием сервисов АИС.</li>
      </ul>
      <div class="public-body__subt">9. Иные положения</div>
      <ul class="public-body__list">
        <li>9.1. Настоящее Соглашение представляет собой договор между Пользователем и РСМ относительно порядка использования сервисов АИС и (или) его отдельных функций, и заменяет собой все предыдущие соглашения между Пользователем и РСМ.</li>
        <li>Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации. Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством Российской Федерации. Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением, разрешаются в порядке, установленном действующим законодательством Российской Федерации, по нормам российского права.</li>
        <li>9.2. В отношении услуг, оказываемых в рамках настоящего Соглашения на безвозмездной основе, нормы о защите прав потребителей, предусмотренные законодательством Российской Федерации, не могут быть применимыми к отношениям между Пользователем и РСМ.</li>
        <li>9.3. Ничто в Соглашении не может пониматься как установление между Пользователем и РСМ агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то иных отношений, прямо не предусмотренных Соглашением.</li>
        <li>9.4. Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений Соглашения.</li>
        <li>9.5. Бездействие со стороны РСМ в случае нарушения Пользователем либо иными пользователями положений Соглашений не лишает РСМ права предпринять соответствующие действия в защиту своих интересов позднее, а также не означает отказа РСМ от своих прав в случае совершения в последующем подобных либо сходных нарушений.</li>
        <li>9.6. Настоящее Соглашение составлено на русском языке и в некоторых случаях может быть предоставлено Пользователю для ознакомления на другом языке. В случае расхождения русскоязычной версии Соглашения и версии Соглашения на ином языке, применяются положения русскоязычной версии настоящего Соглашения.</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Privacy'
}
</script>
